import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { successHandler, errorHandler } from '@/utils/toastHandler';
import useCountryDetail from '@/hooks/useCountryDetail';
import { Checkbox, FormControl, FormControlLabel, FormGroup, TextField } from '@mui/material';
import CryptoJS from 'crypto-js';

import { InputWrapper, MultiInputWrapper, FormTitle, IconWrapper } from '@/components/Container/AuthContainer/styles';
import { Button } from '@/components/UI/Button';
import { Box, FormBox } from '@/layout/Box';
import { FormSelect } from '@/components/UI/Select';
import { Flex, LoadingPage } from '@/layout';
import { Close, Visible } from '@/components/Icon';

import { getYellowPageSearch } from '@/api/auth.api';
import {
  registrationAccount,
  switchLang,
  finishSubAccount,
  registerCompanyInfoWithParentCompany,
} from '@/api/auth.api';
import { useAppDispatch, useAppSelector } from '@/redux/store.hook';
import { getAuthSelector, signIn } from '@/redux/auth/auth.slice';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import useRegistrationValidation from '@/hooks/requireSchema/useRegistrationValidation';

import { converToMultiLangSelectData, convertCountryCode } from '@/utils/convert';

import { countryDetail, title, country } from '@/data/select';
import { LabelWrapper } from '@/components/Account/PreviewApplicationInfo/styled';
import TermsModal from './TermsModal';
import useCurrentLang from '@/hooks/useCurrentLang';
import useResize from '@/hooks/useResize';
import { theme } from '@/theme/Theme';
import { Searchbar, SearchbarMask } from './styles';
import { Text } from '@/components/UI/Typography/Text';
import { getTempData, removeTempData, saveTempData } from '@/utils/localStorageSave';
import { debounce } from 'lodash';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setReadOnly?: React.Dispatch<React.SetStateAction<boolean>>;
  type?: string;
}

const RegisterForm = ({
  setCurrentStep,
  setTitle,
  setActiveStep,
  setReadOnly = () => {},
  type = 'regist',
}: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet, isMobile } = useResize();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const stepLine = t('pages.auth.registrationStepLine', { returnObjects: true });

  const {
    mobileRegister,
    regionRegister,
    jobPositionRegister,
    companyNameRegister,
    giveNameRegister,
    familyNameRegister,
  } = useRegistrationValidation().companyInfo();
  const auth = useAppSelector(getAuthSelector);
  const lang = useCurrentLang();
  const dispatch = useAppDispatch();
  const { userData } = auth;

  // 設置表單與驗證
  const {
    handleSubmit,
    control,
    watch,
    formState: { dirtyFields },
    setValue,
    register,
  } = useForm<Omit<RegistrationAccountForm, 'role'>>();

  const { passwordRegister, emailRegister } = useAuthValidation();

  // 搜尋現有公司名稱名稱
  // const [showSearchList, setShowSearchList] = useState<boolean>(false);
  const [exsistCompany, setExsistCompany] = useState<MemberProfile[]>([]);
  const [companyId, setCompanyId] = useState<string | undefined>();
  const [stopSearch, setStopSearch] = useState<boolean>(false);

  // 監視countryName的變化, 若有變化則打api查詢公司資料並存入exsistCompany
  const watchCountryName = watch('acc_coname');

  useEffect(() => {
    if (!watchCountryName) return;
    if (type !== 'regist') return;

    let cancelTokenSource = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        if (stopSearch) return;
        const res = (await getYellowPageSearch('?acc_coname=' + watchCountryName, {
          cancelToken: cancelTokenSource.token,
        })).data;
        if (res.success) {
          setExsistCompany(res.data.data);
          setCompanyId(undefined);
        }
      } catch (error: any) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          // handle error
        }
      } finally {
        setStopSearch(false);
      }
    };

    const debouncedFetchData = debounce(fetchData, 300);

    debouncedFetchData();

    return () => {
      cancelTokenSource.cancel('Operation canceled due to new request.');
    };
  }, [watchCountryName]);

  const selectHandler = (companyName: string, companyId: string) => {
    setValue('acc_coname', companyName);
    setCompanyId(companyId);
    setExsistCompany([]);
    setStopSearch(true);
  };

  const clearListHandler = () => {
    setValue('acc_coname', '');
    setExsistCompany([]);
    setCompanyId(undefined);
    setStopSearch(true);
  };

  // 取得國家資訊
  const getCountry = (code: string) => {
    const defaultCountry = country['en'].filter((country: CountryPorps) => country.countryCode === code);
    return {
      acc_timezone: defaultCountry[0].timezone,
    };
  };

  const getCountryCode = (countryName: string) => {
    const defaultCountry = country['en'].filter((countryDetail: CountryPorps) => countryDetail.name === countryName);
    return {
      countryCode: defaultCountry[0].countryCode,
    };
  };

  const changeCountryName = (countryCode: string) => {
    const correctCountry = country['en'].find((item) => item.countryCode === countryCode);
    return correctCountry ? correctCountry.name : '';
  };

  // 監視country的變化, 若有新值則將country的值同樣設定到acc_MobileCountryCode
  const watchCountry = watch('acc_country');
  const watchMobileCountry = watch('acc_MobileCountryCode');

  useEffect(() => {
    if (!watchCountry) return;
    const { countryCode } = getCountryCode(watchCountry);
    setValue('acc_MobileCountryCode', convertCountryCode(countryCode));
  }, [watchCountry]);

  useEffect(() => {
    if (watchCountry && watchCountry.includes('Other')) {
      register('acc_country_other', { required: t('required.input', { input: t('form.country.label') }) });
    }
  }, [watchCountry]);

  useEffect(() => {
    if (!watchMobileCountry) return;
    const newCountryName = changeCountryName(watchMobileCountry.split(' ')[0]);
    setValue('acc_country', newCountryName);
  }, [watchMobileCountry]);

  // 重複密碼判斷
  const passwordRef = useRef('');
  const currentPassword = (passwordRef.current = watch('password', ''));

  // 條款modal控制器
  const [checkBox, setCheckBox] = useState({
    infoCollect: false,
    terms: false,
    dataTransfer: false,
  });

  const { infoCollect, terms, dataTransfer } = checkBox;
  const error = [infoCollect, terms].filter((v) => v).length !== 2;

  const handleChange = (value: string, current: boolean) => {
    setCheckBox({ ...checkBox, [value]: !current });
    handleClose(value);
  };

  const [open, setOpen] = useState({
    infoCollect: false,
    terms: false,
  });

  const handleClose = (key: string) => {
    setOpen({ ...open, [key]: false });
  };

  const handleOpen = (key: string) => {
    setOpen({ ...open, [key]: true });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showComfirmPassword, setShowComfirmPassword] = useState(false);

  // 送出表單
  const onSubmit = handleSubmit(async (data) => {
    const countryCode = data.acc_MobileCountryCode.split(' ')[0];
    const { acc_timezone } = getCountry(countryCode);

    // 類型為子帳號時的路線
    if (type === 'subAccount') {
      try {
        setIsLoading(true);
        const rawData = {
          ...data,
          password: CryptoJS.MD5(data.password).toString(),
          passwordConfirmation: CryptoJS.MD5(data.password).toString(),
          role: '1',
          acc_timezone,
          acc_countryCode: countryCode,
          acc_MobileCountryCode: countryCode,
          tcAgreement: true,
          PICsAgreement: true,
        };

        const res = (await finishSubAccount(rawData)).data;

        if (res.success) {
          await switchLang({ lang });
          dispatch(
            signIn({ isLogin: true, accessToken: res.data.access_token, userData: res.data.user, registDone: false, refreshTokenTime: Date.now() + 30 * 60 * 1000 })
          );
          successHandler(t('success.submit'));
          navigate('/home');
        }
      } catch (error: any) {
        setIsLoading(false);
        errorHandler(error.response.data.data);
      }

      // 結束後就離開, 不再執行下面的程式
      return;
    }

    // 類型為註冊時的路線
    try {
      setIsLoading(true);

      // 完成帳號建立的基礎資料送出
      const rawData = {
        ...data,
        password: CryptoJS.MD5(data.password).toString(),
        passwordConfirmation: CryptoJS.MD5(data.password).toString(),
        role: '1',
        acc_timezone,
        acc_countryCode: countryCode,
        acc_MobileCountryCode: countryCode,
        tcAgreement: true,
        PICsAgreement: true,
      };

      const res = (await registrationAccount(rawData)).data;

      if (res.success) {
        // 送出成功後將暫存資料刪除
        removeTempData();

        dispatch(
          signIn({ isLogin: true, accessToken: res.data.access_token, userData: res.data.user, registDone: false, refreshTokenTime: Date.now() + 30 * 60 * 1000 })
        );
        await switchLang({ lang });

        // 若companyId有選擇 代表要套用現有公司資料 先打api送出讓後端處理資料複製
        if (companyId) {
          await registerCompanyInfoWithParentCompany({ parent_company_id: companyId });
          setReadOnly(true);
        }

        setSearchParams({
          step: '2',
        });
        setCurrentStep('2');
        setActiveStep(1);
        setTitle(stepLine[1]);
        successHandler(t('success.submit'));
      }
    } catch (error: any) {
      setIsLoading(false);
      errorHandler(error.response.data.data);
    }
  });

  // 進入時回填資料
  useEffect(() => {
    if (type === 'subAccount') {
      const token = searchParams.get('token');
      const email = searchParams.get('email');
      const companyName = searchParams.get('companyName');
      if (!token) {
        errorHandler(t('error.server'));
        navigate('/');
      } else if (!email) {
        errorHandler(t('error.server'));
        navigate('/');
      } else if (!companyName) {
        errorHandler(t('error.server'));
        navigate('/');
      } else {
        setValue('verificationCode', token);
        setValue('email', email);
        setValue('acc_coname', companyName);
      }
    } else {
      setValue('verificationCode', userData.verificationCode);
    }

    if (!userData.acc_coname) {
      const tempData = getTempData();
      if (!tempData) return;
      setValue('acc_coname', tempData.acc_coname);
      setValue('acc_jobPosition', tempData.acc_jobPosition);
      setValue('acc_title', tempData.acc_title ? tempData.acc_title : '');
      setValue('acc_firstName', tempData.acc_firstName);
      setValue('acc_lastName', tempData.acc_lastName);
      setValue('acc_country', changeCountryName(tempData.acc_countryCode));
      setValue('acc_country_other', tempData.acc_country_other ? tempData.acc_country_other : '');
      setValue('acc_MobileCountryCode', convertCountryCode(tempData.acc_countryCode));
      setValue('acc_mobile', tempData.acc_mobile);
      setCheckBox({
        infoCollect: true,
        terms: true,
        dataTransfer: true,
      });
    } else {
      setValue('acc_coname', userData.acc_coname);
      setValue('acc_jobPosition', userData.acc_jobPosition);
      setValue('acc_title', userData.acc_title ? userData.acc_title : '');
      setValue('acc_firstName', userData.acc_firstName);
      setValue('acc_lastName', userData.acc_lastName);
      setValue('acc_country', changeCountryName(userData.acc_countryCode));
      setValue('acc_country_other', userData.acc_country_other ? userData.acc_country_other : '');
      setValue('acc_MobileCountryCode', convertCountryCode(userData.acc_countryCode));
      setValue('acc_mobile', userData.acc_mobile);
      setCheckBox({
        infoCollect: true,
        terms: true,
        dataTransfer: true,
      });
    }
  }, [userData]);

  return (
    <>
      <LoadingPage isLoading={isLoading} />
      <FormBox onSubmit={onSubmit} isTablet={isTablet}>
        <FormTitle>{t('companyRepresentativeInformation')}</FormTitle>
        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'acc_coname'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <TextField
                  label={t('form.companyName.label')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                  disabled={type === 'subAccount' ? true : false}
                />
                {
                  // 若有搜尋結果則顯示清除按鈕
                  exsistCompany.length !== 0 && (
                    <IconWrapper onClick={clearListHandler}>
                      <Close.CloseSVG width={40} height={40} />
                    </IconWrapper>
                  )
                }
                {companyId && (
                  <IconWrapper onClick={clearListHandler}>
                    <Close.CloseSVG width={40} height={40} />
                  </IconWrapper>
                )}
                {companyId && (
                  <Text marginTop={'5px'} color={theme.colors.secondary500}>
                    {t('pages.auth.reminder')}
                  </Text>
                )}
              </>
            )}
            rules={companyNameRegister}
          />
          <Box display={exsistCompany.length === 0 ? 'none' : 'block'} width={'100%'}>
            <Searchbar>
              {exsistCompany.length !== 0 &&
                exsistCompany.map((company) => (
                  <div
                    className="item"
                    key={company.acc_coname}
                    onClick={() => selectHandler(company.acc_coname, company.id.toString())}
                  >
                    <span>{company.acc_coname}</span>
                    <img
                      src={company.acc_logo ? company.acc_logo : '/images/default_company_logo.jpg'}
                      alt="company_logo"
                    />
                  </div>
                ))}
            </Searchbar>
            <SearchbarMask onClick={() => setExsistCompany([])} />
          </Box>
        </InputWrapper>

        <MultiInputWrapper isMobile={isMobile}>
          <FormSelect
            control={control}
            data={converToMultiLangSelectData(title, lang)}
            inputLabel={t('form.title.label')}
            selectLabel={'acc_title'}
            name={'acc_title'}
          />
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_firstName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.giveName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={giveNameRegister}
          />
          <Controller
            control={control}
            defaultValue={''}
            name={'acc_lastName'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.familyName.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={familyNameRegister}
          />
        </MultiInputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'email'}
            defaultValue={userData.email}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.email.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                disabled
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={emailRegister}
          />
        </InputWrapper>

        <MultiInputWrapper isMobile={isMobile}>
          <Controller
            control={control}
            name={'acc_jobPosition'}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.jobPosition.label')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={jobPositionRegister}
          />

          <FormSelect
            control={control}
            data={useCountryDetail('name')}
            inputLabel={t('form.region.label')}
            selectLabel={'Region'}
            name={'acc_country'}
            register={regionRegister}
          />
        </MultiInputWrapper>

        {watchCountry && watchCountry.includes('Other') && (
          <InputWrapper>
            <Controller
              control={control}
              name={'acc_country_other'}
              defaultValue={''}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={'form.countryOther.label'}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          </InputWrapper>
        )}

        <MultiInputWrapper isMobile={isMobile} style={{ height: 'auto' }}>
          <Box style={{ width: isMobile ? '100%' : '200px' }}>
            <FormSelect
              control={control}
              data={useCountryDetail('countryCode')}
              inputLabel={t('form.countryCode.label')}
              selectLabel={t('form.countryCode.label')}
              name={'acc_MobileCountryCode'}
            />
          </Box>
          <Controller
            control={control}
            name={'acc_mobile'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.mobile.label')}
                placeholder={t('form.mobile.placeholder')}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
            rules={mobileRegister}
          />
        </MultiInputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            defaultValue=""
            name={'password'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <TextField
                  label={t('form.registrationPassword.label')}
                  placeholder={t('form.registrationPassword.placeholder')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : t('form.registrationPassword.placeholder')}
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="off"
                />
                <IconWrapper onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visible.VisibleSVG /> : <Visible.VisibleOffSVG />}
                </IconWrapper>
              </>
            )}
            rules={passwordRegister}
          />
        </InputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            defaultValue=""
            name={'passwordConfirmation'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <TextField
                  label={t('form.reconfirmNewPassword.label')}
                  placeholder={t('form.reconfirmNewPassword.placeholder')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  type={showComfirmPassword ? 'text' : 'password'}
                  autoComplete="off"
                />
                <IconWrapper onClick={() => setShowComfirmPassword(!showComfirmPassword)}>
                  {showComfirmPassword ? <Visible.VisibleSVG /> : <Visible.VisibleOffSVG />}
                </IconWrapper>
              </>
            )}
            rules={{
              required: t('required.reconfirmNewPassword'),
              validate: (value) => value === currentPassword || t('error.reconfirmNewPassword'),
            }}
          />
        </InputWrapper>

        <Controller
          control={control}
          name={'coupon'}
          defaultValue={''}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('form.coupon.label')}
              variant="standard"
              value={value}
              sx={{ width: '100%' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
              disabled={type === 'subAccount' ? true : false}
            />
          )}
        />

        <InputWrapper style={{ height: 'auto', display: 'none' }}>
          <Controller
            control={control}
            defaultValue=""
            name={'verificationCode'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.verificationCode.label')}
                placeholder={''}
                variant="standard"
                value={value}
                sx={{ width: '100%' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type={'text'}
                autoComplete="off"
              />
            )}
            rules={{
              required: t('required.verificationCode'),
            }}
          />
        </InputWrapper>

        <FormControl sx={{ paddingTop: '30px' }} required error={error} component="fieldset">
          <FormGroup style={{ gap: '15px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: '#999999',
                    '&.Mui-checked': {
                      color: '#999999',
                    },
                  }}
                  checked={infoCollect}
                  onChange={() => handleOpen('infoCollect')}
                  name="infoCollect"
                />
              }
              label={
                <LabelWrapper>
                  <Flex gridGap={isMobile ? '0' : '10px'} flexDirection={isMobile ? 'column' : 'row'}>
                    <span>{t('form.terms.label.pic.prev')}</span>
                    <span className="term">{t('form.terms.label.pic.last')}</span>
                  </Flex>
                </LabelWrapper>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: '#999999',
                    '&.Mui-checked': {
                      color: '#999999',
                    },
                  }}
                  checked={dataTransfer}
                  onChange={() => handleChange('dataTransfer', dataTransfer)}
                  name="dataTransfer"
                />
              }
              label={<LabelWrapper>{t('form.terms.label.dataShare')}</LabelWrapper>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: '#999999',
                    '&.Mui-checked': {
                      color: '#999999',
                    },
                  }}
                  checked={terms}
                  onChange={() => handleOpen('terms')}
                  name="terms"
                />
              }
              label={
                <LabelWrapper>
                  <Flex gridGap={isMobile ? '0' : '10px'} flexDirection={isMobile ? 'column' : 'row'}>
                    <span>{t('form.terms.label.t&c.prev')}</span>
                    <span className="term">{t('form.terms.label.t&c.last')}</span>
                  </Flex>
                </LabelWrapper>
              }
            />
          </FormGroup>
        </FormControl>
        <TermsModal open={open} handleClose={handleClose} handleChange={handleChange} />

        <Flex gridGap={'10px'} marginTop={50}>
          <Button
            size={'lg'}
            variant={'primary'}
            rounded={false}
            width={isMobile ? '100%' : 'unset'}
            disabled={!infoCollect || !terms || !dataTransfer || Object.keys(dirtyFields).length < 2 || isLoading}
          >
            {t('buttons.next')}
          </Button>
          <Button
            type={'button'}
            size={'lg'}
            variant={'primary'}
            rounded={false}
            width={isMobile ? '100%' : 'unset'}
            onClick={() => {
              saveTempData({ ...watch() });
              successHandler(t('success.saved'));
            }}
          >
            {t('buttons.save')}
          </Button>
        </Flex>
      </FormBox>
    </>
  );
};

export default RegisterForm;
