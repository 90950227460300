import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { getUserNotifications } from '@/api/notifications.api';
import { loginMember, loginToken, switchLang } from '@/api/auth.api';
import { useDispatch } from 'react-redux';
import { getAuthSelector, signIn } from '@/redux/auth/auth.slice';
import { getNotification } from '@/redux/notification/notification.slice';
import { useAppSelector } from '@/redux/store.hook';

import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { Button } from '@/components/UI/Button';
import { Text } from '@/components/UI/Typography/Text';
import StyledLink from '@/components/UI/Link';
import { Box } from '@/layout/Box';
import { Flex } from '@/layout/Flex';

import PATH from '@/router/path';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import { errorHandler } from '@/utils/toastHandler';
import { LoadingPage } from '@/layout';
import { VerifyModal } from '@/components/UI/Modal';
import useResize from '@/hooks/useResize';

interface LoginDataProps {
  email: string;
  password: string;
  token: string;
}

interface Step1Props {
  setSteps: React.Dispatch<React.SetStateAction<number>>;
  setLoginData: React.Dispatch<React.SetStateAction<LoginDataProps>>;
}

const Step1 = ({ setSteps, setLoginData }: Step1Props) => {
  // 宣告hooks做登入後轉跳
  const navigate = useNavigate();
  const { isMobile, isTablet } = useResize();
  // user data
  const auth = useAppSelector(getAuthSelector);
  // 檢查是否有要求跳轉指定
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [announce, setAnnounce] = useState(true);
  const [failedCounter, setFailedCounter] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // @ts-ignore
  const { t } = useTranslation();

  useEffect(() => {
    let path = searchParams.get('path');

    if (path && announce) {
      setAnnounce(false);
      errorHandler(t('error.login'));
      return;
    }

    if (!path && auth.userData.name && auth.isLogin) {
      navigate('/home', { replace: true });
    }
  }, []);

  const {
    handleSubmit,
    control,
    setError,
    formState: { isDirty },
  } = useForm<MemberSignInFormFields>();

  const { emailRegister, passwordRegister } = useAuthValidation();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    try {
      const res = (await loginMember({ ...data, password: CryptoJS.MD5(data.password).toString() })).data;

      if (res.success) {
        setLoginData({
          token: res.data.one_time_token,
          email: data.email,
          password: CryptoJS.MD5(data.password).toString(),
        });
        setSteps(2);
      }
    } catch (error: any) {
      const errorData = error.response.data.data;

      if (typeof errorData === 'object' && errorData[0] !== 'Unauthorized') {
        setFailedCounter(6);
        setError('email', {
          type: 'server',
          message: error.response.data.data,
        });
        return;
      }

      if (failedCounter + 1 >= 6) {
        setError('email', {
          type: 'server',
          message: error.response.data.data,
        });
        return;
      }

      setFailedCounter(failedCounter + 1);
      setError('email', {
        type: 'server',
        message: t('error.email'),
      });
      setError('password', {
        type: 'server',
        message: t('error.password'),
      });
      if (failedCounter + 1 >= 3) {
        setShowModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <LoadingPage isLoading={isLoading} />
      <VerifyModal type={'login'} failedCounter={failedCounter} showModal={showModal} setShowModal={setShowModal} />
      <InputWrapper>
        <Controller
          control={control}
          defaultValue=""
          name={'email'}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={t('form.email.label')}
              placeholder={t('form.email.placeholder')}
              variant="standard"
              value={value}
              sx={{ width: '100%', paddingBottom: '30px' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={emailRegister}
        />
        <Controller
          control={control}
          defaultValue=""
          name={'password'}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Flex flexDirection={'column'} alignItems={'flex-end'}>
              <TextField
                label={t('form.password.label')}
                placeholder={t('form.password.placeholder')}
                variant="standard"
                value={value}
                sx={{ width: '100%', paddingBottom: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type={'password'}
                autoComplete="off"
              />
              <StyledLink to={PATH.auth.forgotPassword}>{t('forgotPassword')}</StyledLink>
            </Flex>
          )}
          rules={passwordRegister}
        />
      </InputWrapper>
      <Box>
        <Flex gridGap={'10px'} marginBottom={15} flexDirection={isMobile ? 'column' : 'row'}>
          <Button size={'lg'} variant={'primary'} disabled={!isDirty || failedCounter > 5}>
            {t('buttons.signIn')}
          </Button>
          <Button onClick={() => navigate('/')} type={'button'} size={'lg'} variant={'primary'}>
            {t('buttons.cancel')}
          </Button>
        </Flex>
        <Text>
          <StyledLink to={PATH.auth.signUp}>{t('registration')}</StyledLink>
        </Text>
      </Box>
    </form>
  );
};

const Step2 = ({ loginData }: { loginData: LoginDataProps }) => {
  // 宣告hooks做登入後轉跳
  const navigate = useNavigate();
  const { isMobile } = useResize();
  // user data
  // 檢查是否有要求跳轉指定
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [attemptCounter, setAttemptCounter] = useState(0);

  const dispatch = useDispatch();
  // @ts-ignore
  const { t, i18n } = useTranslation();

  const {
    handleSubmit,
    control,
    setError,
    formState: { isDirty },
  } = useForm<MemberSignInTokenFormFields>();

  const { tokenRegister } = useAuthValidation();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    const rawData = {
      ...data,
      one_time_token: loginData.token,
      password: loginData.password,
      email: loginData.email,
    };

    try {
      const res = (await loginToken(rawData)).data;

      if (res.success) {
        if (res.data.user.stage < 4) {
          await dispatch(
            signIn({
              isLogin: true,
              accessToken: res.data.access_token,
              userData: res.data.user,
              registDone: false,
              refreshTokenTime: Date.now() + 30 * 60 * 1000,
            })
          );
          // 未完成註冊, 根據stage的數字跳轉到指定的頁面
          navigate(`/registration?step=${res.data.user.stage + 1}`, { replace: true });
          return;
        } else {
          // 若返回的語系不為空, 則按使用者的語系設定i18n, 若為空則送出api設定當前語系為i18n的語系
          if (res.data.user.lang) {
            i18n.changeLanguage(res.data.user.lang);
          } else {
            await switchLang({ lang: i18n.language });
          }
          await dispatch(
            signIn({
              isLogin: true,
              accessToken: res.data.access_token,
              userData: res.data.user,
              registDone: true,
              refreshTokenTime: Date.now() + 30 * 60 * 1000,
            })
          );
          const notificationRes = (await getUserNotifications()).data;
          dispatch(getNotification(notificationRes.data));
        }

        if (searchParams.get('path')) {
          navigate(searchParams.get('path') as string, { replace: true });
        } else {
          navigate('/home', { replace: true });
        }
        // navigate('/home', { replace: true });
        // if (!location.state?.redir) {
        //   if (searchParams.get('path')) {
        //     navigate(searchParams.get('path') as string, { replace: true });
        //     setSearchParams('');
        //     return;
        //   }
        //   navigate('/home', { replace: true });
        // } else {
        //   navigate(-2);
        // }
      }
    } catch (error: any) {
      let errorData = '';
      console.log(error);
      if (error.response.status && error.response.status === 429) {
        errorData = error.response.data.message;
      } else {
        errorData = error.response.data.data;
      }
      setAttemptCounter(attemptCounter + 1);
      setError('code', { type: 'custom', message: errorData });
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <LoadingPage isLoading={isLoading} />
      <Text>{t('pages.auth.signIn.illustrate.step2')}</Text>
      <InputWrapper>
        <Controller
          control={control}
          defaultValue=""
          name={'code'}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label={`${t('form.code.label')} ${attemptCounter > 0 ? `(${t('attempt')} ${attemptCounter})` : ''}`}
              variant="standard"
              value={value}
              sx={{ width: '100%', paddingBottom: '30px' }}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              autoComplete="off"
            />
          )}
          rules={tokenRegister}
        />
      </InputWrapper>
      <Box>
        <Flex gridGap={'10px'} flexDirection={isMobile ? 'column' : 'row'}>
          <Button size={'lg'} variant={'primary'} disabled={!isDirty || attemptCounter > 4}>
            {t('buttons.submit')}
          </Button>
          <Button onClick={() => navigate('/')} type={'button'} size={'lg'} variant={'primary'}>
            {t('buttons.cancel')}
          </Button>
        </Flex>
      </Box>
    </form>
  );
};

export { Step1, Step2 };
