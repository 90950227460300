import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Controller, useForm } from 'react-hook-form';
import { Box, TextField } from '@mui/material';
import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { Button } from '@/components/UI/Button';
import { Flex } from '@/layout';

const TestMCE = () => {
  const editorRef = useRef<any>(null);
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<{ title: string; name: string; email: string }>();

  const onSubmit = handleSubmit((data) => {
    // 將data的object內容透過array的方式取出並轉成html結構
    // 放入editorRef.current.setContent(content)中
    const content = Object.entries(data)
      .map(([key, value]) => `<p>${value}</p>`)
      .join('');
    editorRef.current.setContent(content);
  });

  return (
    <Flex justifyContent={'center'} gridGap={'30px'}>
      <Box width={'500px'}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <InputWrapper>
            <Controller
              control={control}
              defaultValue=""
              name={'title'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="標題"
                  placeholder=""
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Controller
              control={control}
              defaultValue=""
              name={'name'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="姓名"
                  placeholder=""
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <Controller
              control={control}
              defaultValue=""
              name={'email'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label="eMail"
                  placeholder=""
                  variant="standard"
                  value={value}
                  sx={{ width: '100%' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
            />
          </InputWrapper>
          <Box>
            <Button
              size={'lg'}
              width={'100%'}
              variant={'primary'}
              rounded={false}
              marginBottom={15}
              disabled={!isDirty}
              type={'submit'}
            >
              測試
            </Button>
          </Box>
        </form>
      </Box>
      <div>
        <Editor
          apiKey="xr0aron79kx677u47ppizlnzj1qzyiwnxmyzoh61ocluovc9"
          onInit={(_evt, editor) => (editorRef.current = editor)}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'help',
              'wordcount',
            ],
            toolbar:
              'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
        />
      </div>

      {/* <button onClick={log}>Log editor content</button> */}
    </Flex>
  );
};

export default TestMCE;
