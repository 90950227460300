import styled from 'styled-components';

const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1490px;
  margin: 0 auto;
  padding: 100px 20px 185px;
  min-height: 65vh;
`;

const Title = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.xl};
  font-weight: 700;
  line-height: 70px;
  color: ${(p) => p.theme.colors.gray500};
  margin-bottom: 80px;
  text-align: center;
`;

const CardsList = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
`;

const CardWrapper = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  width: ${(p) => (p.isMobile ? '100%' : p.isTablet ? 'calc(50% - 30px)' : 'calc(33.3% - 30px)')};
  background-color: ${(p) => p.theme.colors.light};
  filter: drop-shadow(7px 7px 15px rgba(0, 0, 0, 0.2));
  border-radius: 15px;
  overflow: hidden;

  .pic {
    width: 100%;
    height: ${(p) => (p.isMobile ? '200px' : '270px')};
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: ${(p) => (p.isMobile ? '10px 15px' : '20px 30px')};
    height: ${(p) => (p.isMobile ? 'calc(100% - 200px)' : 'calc(100% - 270px)')};

    p {
      font-family: 'Roboto', sans-serif;
      font-size: ${(p) => p.theme.fontSizes.md};
      color: ${(p) => p.theme.colors.gray400};
      font-weight: 400;
      line-height: 30px;
    }

    a {
      font-family: 'Roboto', sans-serif;
      font-size: ${(p) => p.theme.fontSizes.md};
      color: ${(p) => p.theme.colors.secondary400};
      text-decoration: underline;
      cursor: pointer;
      margin-left: auto;
      margin-top: auto;
    }
  }
`;

export { CardsList, ContainerWrapper, Title, CardWrapper };
